import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useLazyCheckSpaceClusterAvailabilityQuery,
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyGetSpaceClustersXYQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
  useLazyViewSpaceClusterConsumptionByYearAndMonthQuery,
  useLazyViewSpaceClusterHistoricalDataQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import {
  useLazyEnergyPredictionByYearMonthQuery,
  useLazyGetEnergyConsumptionQuery,
} from "../../../redux/api/energy-consumption/energyConsumptionAPI";
import { useLazyGetEnergyGenerationDataQuery } from "../../../redux/api/solar/solarAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { useLazyViewExternalConsumptionDataPercentagesQuery } from "../../../redux/api/usage/usageAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import {
  ISpaceClusterWithPowerGeneratorIds,
  SpaceClustersResponseDTO,
  ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import ViewConsumedSpaceClusterByYearResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumed-space-cluster-by-year-response-dto";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-generation-response-dto";
import { IExternalConsumptionPercentage } from "../../../shared/oversight-core/dtos/response-dtos/view-external-consumption-data-percentages-response-dto";
import ViewScheduledPowerConsumersSortedByEnergyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-scheduled-power-consumers-sorted-by-energy-response-dto";
import IViewSpaceClusterHistoricalDataResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-space-cluster-historical-data-response-dto";
import { EConsumptionValueComparison } from "../../../shared/oversight-core/enums/consumption-comparison";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EDemandPeriod } from "../../../shared/oversight-core/enums/demand-period";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { EProviders } from "../../../shared/oversight-core/enums/providers";
import { EScheduleViewType } from "../../../shared/oversight-core/enums/schedule-view-type";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import { EViewType } from "../../../shared/oversight-core/enums/view-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ConsumptionDataView } from "../../../shared/oversight-core/interfaces/entities/consumption-data-view";
import { IEnergyView } from "../../../shared/oversight-core/interfaces/entities/energy-view";
import IExtendedEnergyConsumers from "../../../shared/oversight-core/interfaces/extended-energy-consumers";
import IMonthlyEnergySummaryView from "../../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import UsageCard from "../../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage-card";
import HighPowerDevices from "../../../shared/oversight-core/shared-pages/dashboard-widgets/high-power-devices/high-power-devices";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { capitalizeFirstLetter } from "../../../shared/oversight-core/utils/capitalize-first-letter";
import { devicePath } from "../../../shared/oversight-core/utils/device-path";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { showErrorMessage } from "../../../shared/oversight-core/utils/toast";
import UsageByTime from "../usage/components/usage-by-time/usage-by-time";
import ICheckSpaceClusterAvailabilityResponseDTO from "./../../../shared/oversight-core/dtos/response-dtos/check-space-cluster-availability-response-dto";
import UsageInfoModal from "./models/usage-info-modal/usage-info-modal";

interface ChartData {
  consumptionType: "UNITS" | "BILL";
  year: 0;
  months: string[];
  solar: number[];
  grid: number[];
  manual: number[];
  semiAuto: number[];
}

const scheduleViewType = [
  {
    label: "Usage from Both Manual And Semi-Automated",
    value: EScheduleViewType.ALL,
  },
  {
    label: "Usage from Manual Schedule",
    value: EScheduleViewType.MANUAL,
  },
  {
    label: "Usage from Semi-Automated Schedule",
    value: EScheduleViewType.SEMI_AUTOMATED,
  },
];

const defaultUsage = {
  energyBill: 0,
  energyInUnits: 0,
};

const viewTypes = [
  { name: "Consumed", type: EViewType.CONSUMED },
  { name: "Manually Scheduled", type: EViewType.MANUALLY_SCHEDULED },
  {
    name: "Semi-Automatically Scheduled",
    type: EViewType.SEMI_AUTOMATICALLY_SCHEDULED,
  },
];

const defaultSpaceClusterAvailability: ICheckSpaceClusterAvailabilityResponseDTO =
  {
    controllersAvailable: false,
    manualSchedulesAvailable: false,
    semiAutomatedSchedulesAvailable: false,
    consumedSpaceClusterAvailable: false,
  };

const Home = () => {
  const profile = useSelector(selectProfile);
  const [selectedScheduleViewType, setSelectedScheduleViewType] =
    useState<Option>(scheduleViewType[0]);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [semiAutomatedUsageThisMonth, setSemiAutomatedUsageThisMonth] =
    useState({
      ...defaultUsage,
    });
  // const [semiAutomatedUsageLastMonth, setSemiAutomatedUsageLastMonth] =
  //   useState({
  //     ...defaultUsage,
  //   });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultUsage,
  });
  const [actualLastMonth, setActualLastMonth] = useState({
    ...defaultUsage,
  });
  const [usagePrediction, setUsagePrediction] = useState<
    IEnergyView | undefined
  >(undefined);
  const [consumers, setConsumers] = useState<IExtendedEnergyConsumers[]>([]);
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chartData, setChartData] = useState<ChartData>({
    consumptionType: "UNITS",
    year: 0,
    months: [],
    solar: [],
    grid: [],
    manual: [],
    semiAuto: [],
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [chartDataAvailable, setChartDataAvailable] = useState(false);
  const [
    spaceClustersWithPowerGeneratorIds,
    setSpaceClustersWithPowerGeneratorIds,
  ] = useState<ISpaceClusterWithPowerGeneratorIds[]>([]);
  const [selectedSpace, setSelectedSpace] =
    useState<ISpaceClusterWithPowerGeneratorIds>();
  const [comparisonEnergyThisMonth, setComparisonEnergyThisMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [comparisonEnergyLastMonth, setComparisonEnergyLastMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [actualUsageDataFromThisMonth, setActualUsageDataFromThisMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [actualUsageDataFromLastMonth, setActualUsageDataFromLastMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [showUsageInfoModal, setShowUsageInfoModal] = useState(false);
  const [monthlyEnergySummaryView, setMonthlyEnergySummaryView] = useState<
    IMonthlyEnergySummaryView[]
  >([]);
  const [currentViewType, setCurrentViewType] = useState(0);
  const [
    externalConsumptionDataPercentages,
    setExternalConsumptionDataPercentages,
  ] = useState<IExternalConsumptionPercentage[]>([]);
  const [checkSpaceClusterAvailability, setCheckSpaceClusterAvailability] =
    useState<ICheckSpaceClusterAvailabilityResponseDTO>({
      ...defaultSpaceClusterAvailability,
    });
  const [lastThreeMonthsEnergyView, setLastThreeMonthsEnergyView] =
    useState<IEnergyView>({
      ...defaultUsage,
    });
  const [yearlyEnergyView, setYearlyEnergyView] = useState<IEnergyView>({
    ...defaultUsage,
  });
  const [selectedDataUsageFrom, setSelectedDataUsageFrom] =
    useState<EUsageDataFrom>(EUsageDataFrom.SERVICE_PROVIDER);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpace }] =
    useLazyGetSpacesQuery();
  const [triggerGetSpaceClustersWithPowerGeneratorIds] =
    useLazyGetSpaceClustersXYQuery();
  const [triggerGetActualUsage, { isFetching: isFetchingActualUsage }] =
    useLazyViewSpaceClusterConsumptionByYearAndMonthQuery();
  const [
    triggerGetSemiAutomatedUsage,
    { isFetching: isFetchingSemiAutomatedUsage },
  ] = useLazyGetSpaceClusterEnergyWithComparisonQuery();
  const [
    triggerViewPowerConsumersWithHighConsumption,
    { isFetching: isFetchingHighestPowerConsumers },
  ] = useLazyViewPowerConsumersWithHighConsumptionQuery();
  const [
    triggerViewEnergyGenerationData,
    { isFetching: isFetchingEnergyGeneration },
  ] = useLazyGetEnergyGenerationDataQuery();
  const [
    triggerViewActualConsumptionByYearQuery,
    {
      data: getEnergyConsumption,
      isSuccess: isSuccessEnergyConsumption,
      isFetching: isFetchingActualConsumptionByYear,
    },
  ] = useLazyGetEnergyConsumptionQuery();
  const [
    triggerViewExternalConsumptionDataPercentagesData,
    { isFetching: isFetchingViewExternalConsumptionDataPercentages },
  ] = useLazyViewExternalConsumptionDataPercentagesQuery();
  const [triggerCheckSpaceClusterAvailability] =
    useLazyCheckSpaceClusterAvailabilityQuery();
  const [triggerSpaceClusterHistoricalData] =
    useLazyViewSpaceClusterHistoricalDataQuery();
  const [
    triggerEnergyPredictionByYearMonth,
    { isFetching: isFetchingEnergyPredictionByYearMonth },
  ] = useLazyEnergyPredictionByYearMonthQuery();

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerCheckSpaceClusterAvailability({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ICheckSpaceClusterAvailabilityResponseDTO) => {
          setCheckSpaceClusterAvailability(res);
        })
        .catch(() => {
          setCheckSpaceClusterAvailability({
            ...defaultSpaceClusterAvailability,
          });
        });
    }
  }, [triggerCheckSpaceClusterAvailability, billingSpaceFilter]);

  useEffect(() => {
    if (checkSpaceClusterAvailability.controllersAvailable) {
      setSelectedDataUsageFrom(EUsageDataFrom.TELEMETRY);
    } else if (checkSpaceClusterAvailability.semiAutomatedSchedulesAvailable) {
      setSelectedDataUsageFrom(EUsageDataFrom.SEMI_AUTOMATED_SCHEDULE);
    } else if (checkSpaceClusterAvailability.manualSchedulesAvailable) {
      setSelectedDataUsageFrom(EUsageDataFrom.MANUAL_SCHEDULE);
    } else {
      setSelectedDataUsageFrom(EUsageDataFrom.SERVICE_PROVIDER);
    }
  }, [checkSpaceClusterAvailability]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerSpaceClusterHistoricalData({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: moment(new Date()).year(),
        month: moment(new Date()).month() + 1,
        usageDataFrom: selectedDataUsageFrom,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: IViewSpaceClusterHistoricalDataResponseDTO) => {
          setLastThreeMonthsEnergyView(res.lastThreeMonthsEnergyView);
          setYearlyEnergyView(res.yearlyEnergyView);
        })
        .catch(() => {
          setLastThreeMonthsEnergyView({ ...defaultUsage });
          setYearlyEnergyView({ ...defaultUsage });
        });
    }
  }, [
    triggerSpaceClusterHistoricalData,
    billingSpaceFilter,
    selectedDataUsageFrom,
  ]);

  useEffect(() => {
    if (
      billingSpaceFilter &&
      billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber
    ) {
      triggerViewExternalConsumptionDataPercentagesData({
        year: moment(new Date()).year(),
        month: moment(new Date()).month() + 1,
        accountNumber:
          billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber,
      })
        .unwrap()
        .then((res) => {
          setExternalConsumptionDataPercentages(res.percentages);
        })
        .catch(() => {
          setExternalConsumptionDataPercentages([]);
        });
    }
  }, [billingSpaceFilter]);

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    triggerGetSpaceClustersWithPowerGeneratorIds()
      .unwrap()
      .then((res: SpaceClustersResponseDTO) => {
        setSpaceClustersWithPowerGeneratorIds(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClustersWithPowerGeneratorIds([]);
      });
  }, [triggerGetSpaceClustersWithPowerGeneratorIds]);

  useEffect(() => {
    if (spaceClustersWithPowerGeneratorIds.length > 0) {
      const selectedSpaceCluster = spaceClustersWithPowerGeneratorIds.find(
        (space) => space.id === billingSpaceFilter.spaceCluster.id
      );

      setSelectedSpace(
        selectedSpaceCluster ? { ...selectedSpaceCluster } : undefined
      );
    }
  }, [billingSpaceFilter.spaceCluster.id, spaceClustersWithPowerGeneratorIds]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetActualUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      })
        .unwrap()
        .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
          setActualThisMonth({
            energyBill: res.energy.energyBill,
            energyInUnits: res.energy.energyInUnits,
          });
          setActualLastMonth({
            energyBill: res.previousEnergy.energyBill,
            energyInUnits: res.previousEnergy.energyInUnits,
          });
          setComparisonEnergyThisMonth(
            res.comparedToPreviousMonthConsumedEnergy
          );
          setActualUsageDataFromThisMonth(res.usageDataFrom);
        })
        .catch(() => {
          setActualThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setActualLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setComparisonEnergyThisMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });

      triggerEnergyPredictionByYearMonth({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: moment(new Date()).year(),
        month: moment(new Date()).month() + 1,
        viewEnergyAs: EEnergyViewType.ALL,
      })
        .unwrap()
        .then((res) => {
          setUsagePrediction(res.predictedEnergy);
        })
        .catch(() => {
          setUsagePrediction(undefined);
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      const currentDate = new Date();

      const previousDate = new Date(currentDate);
      previousDate.setMonth(currentDate.getMonth() - 1);

      triggerGetActualUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: previousDate.getFullYear(),
        month: previousDate.getMonth() + 1,
      })
        .unwrap()
        .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
          setComparisonEnergyLastMonth(
            res.comparedToPreviousMonthConsumedEnergy
          );
          setActualUsageDataFromLastMonth(res.usageDataFrom);
        })
        .catch(() => {
          setComparisonEnergyLastMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetSemiAutomatedUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewSpaceClusterEnergyWithComparisonResponseDTO) => {
          setSemiAutomatedUsageThisMonth({
            energyBill: res.currentMonthEnergyView.energyBill,
            energyInUnits: res.currentMonthEnergyView.energyInUnits,
          });
          // setSemiAutomatedUsageLastMonth({
          //   energyBill: res.previousMonthEnergyView.energyBill,
          //   energyInUnits: res.previousMonthEnergyView.energyInUnits,
          // });
        })
        .catch(() => {
          setSemiAutomatedUsageThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          // setSemiAutomatedUsageLastMonth({
          //   energyBill: 0,
          //   energyInUnits: 0,
          // });
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id && selectedDate) {
      const viewEnergyAs: EEnergyViewType =
        selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
      const viewConsumptionAs: EConsumptionViewTypes =
        EConsumptionViewTypes.ALL;
      const viewScheduleAs: EScheduleViewType =
        selectedScheduleViewType.value as EScheduleViewType;

      triggerViewActualConsumptionByYearQuery({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: moment(selectedDate).year(),
        viewEnergyAs,
        viewConsumptionAs,
        viewScheduleAs,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewConsumedSpaceClusterByYearResponseDTO) => {
          setMonthlyEnergySummaryView(res.orderedMonthlyEnergySummary);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    selectedDate,
    selectedButton,
    selectedScheduleViewType.value,
  ]);

  useEffect(() => {
    if (
      selectedButton === 1 &&
      selectedSpace &&
      selectedSpace.powerGeneratorIds?.length > 0
    ) {
      triggerViewEnergyGenerationData({
        powerGeneratorId:
          (selectedSpace && selectedSpace.powerGeneratorIds[0]) || "",
        year: selectedDate.getFullYear(),
      })
        .unwrap()
        .then((res: ViewEnergyGenerationResponseDTO) => {
          setChartData((ps) => {
            ps.solar =
              res.generatedEnergy?.map((ge) => +ge.toFixed(2)) ||
              Array.from({ length: 12 }, () => 0);
            ps.months = res.months || [];
            return { ...ps };
          });
        })
        .catch(() => {
          setChartData((ps) => {
            ps.solar = Array.from({ length: 12 }, () => 0);
            return { ...ps };
          });
        });
    } else {
      setChartData((ps) => {
        ps.solar = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [selectedButton, selectedSpace, selectedDate]);

  useEffect(() => {
    if (isSuccessEnergyConsumption && getEnergyConsumption) {
      const data: ConsumptionDataView =
        getEnergyConsumption.orderedMonthlyEnergySummary.reduce(
          (
            previousValue: ConsumptionDataView,
            currentValue: IMonthlyEnergySummaryView
          ) => {
            previousValue?.months?.push(currentValue.monthName);
            previousValue?.actualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.manualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.semiAutoConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView
                    ?.semiAutomatedScheduleEnergySummary?.energyInUnits || 0
                : currentValue.energySummaryView
                    ?.semiAutomatedScheduleEnergySummary?.energyBill || 0
            );

            return previousValue;
          },
          {
            months: [],
            actualConsumption: [],
            manualConsumption: [],
            semiAutoConsumption: [],
          }
        );

      setChartData((ps) => {
        ps.grid = data.actualConsumption.map((ac) => +ac.toFixed(2));
        ps.manual = data.manualConsumption.map((mc) => +mc.toFixed(2));
        ps.semiAuto = data.semiAutoConsumption.map((sac) => +sac.toFixed(2));
        ps.months = data.months;
        return { ...ps };
      });
    } else {
      setChartData((ps) => {
        ps.grid = Array.from({ length: 12 }, () => 0);
        ps.manual = Array.from({ length: 12 }, () => 0);
        ps.semiAuto = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [isSuccessEnergyConsumption, getEnergyConsumption]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      onViewPowerConsumersWithHighConsumption();
    }
  }, [billingSpaceFilter.spaceCluster.id, currentViewType]);

  const monthsList = chartData.months.map((m) => capitalizeFirstLetter(m));

  useEffect(() => {
    const energyArrays = [
      chartData.grid,
      chartData.manual,
      chartData.semiAuto,
      chartData.solar,
    ];

    const isDataAvailable = energyArrays.some((array) =>
      array.some((units) => units > 0)
    );

    setChartDataAvailable(isDataAvailable);
  }, [chartData]);

  const onViewPowerConsumersWithHighConsumption = () => {
    setConsumers([]);
    triggerViewPowerConsumersWithHighConsumption({
      clusterId: billingSpaceFilter.spaceCluster.id,
      sortingOrder: ESortOrder.DESC,
      limit: 6,
      energyViewType: EEnergyViewType.ALL,
      viewType: viewTypes[currentViewType].type,
      year: moment().year(),
      month: moment().month() + 1,
      zoneOffset: offsetToString(moment().utcOffset()),
    })
      .unwrap()
      .then((res: ViewScheduledPowerConsumersSortedByEnergyResponseDTO) => {
        const extendedPowerConsumers = res.powerConsumers
          ? res.powerConsumers.map((powerConsumer) => {
              const path = devicePath(res?.devicePaths[powerConsumer.id]);
              return { ...powerConsumer, path };
            })
          : [];
        setConsumers(extendedPowerConsumers);
      })
      .catch((error) => {
        if (
          error.status !== 404 &&
          error.ovstErrorCode !== OvstErrorCode.OVST_0028
        ) {
          showErrorMessage("Sorry, An error occurred");
          return;
        }
      });
  };

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          <Row>
            <Col
              className="col-12 col-xl-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="">
                <UsageCard
                  title="Usage This Month"
                  subTitle1="Actual Usage"
                  subTitle2="Scheduled Usage"
                  subTitle3="Usage Prediction"
                  value1={actualThisMonth}
                  value2={semiAutomatedUsageThisMonth}
                  value3={usagePrediction}
                  isFetching={
                    isFetchingSemiAutomatedUsage ||
                    isFetchingActualUsage ||
                    isFetchingEnergyPredictionByYearMonth
                  }
                  consumptionValueComparison={comparisonEnergyThisMonth}
                  usageDataFrom={actualUsageDataFromThisMonth}
                />
              </div>
              {/* <div className="mt-3 ">
                <UsageCard
                  title="Usage Last Month"
                  subTitle1="Scheduled Usage"
                  value1={semiAutomatedUsageLastMonth}
                  isFetching={
                    isFetchingSemiAutomatedUsage || isFetchingActualUsage
                  }
                />
              </div> */}
              <div className="mt-3 ">
                <UsageCard
                  title="Historical Actual Usage"
                  subTitle1="Last Month"
                  subTitle2="Ave. Last 3 Months"
                  subTitle3="Ave. Last year"
                  value1={actualLastMonth}
                  value2={lastThreeMonthsEnergyView}
                  value3={yearlyEnergyView}
                  isFetching={
                    isFetchingSemiAutomatedUsage || isFetchingActualUsage
                  }
                  usageDataFrom={actualUsageDataFromLastMonth}
                  consumptionValueComparison={comparisonEnergyLastMonth}
                />
              </div>
            </Col>
            <Col className="mt-3 mt-xl-0">
              <HighPowerDevices
                consumers={consumers || null}
                title={`Devices with Highest Energy Consumption this Month [${viewTypes[currentViewType].name}]`}
                isFetching={isFetchingHighestPowerConsumers}
                viewTypes={viewTypes}
                currentViewType={currentViewType}
                setCurrentViewType={setCurrentViewType}
              />
            </Col>
          </Row>
          {profile?.serviceProvider === EProviders.LECO && (
            <Row className="my-4">
              <Col>
                <UsageByTime
                  isLoading={isFetchingViewExternalConsumptionDataPercentages}
                  day={
                    externalConsumptionDataPercentages.find(
                      (ecdp) => ecdp.demandPeriod === EDemandPeriod.DAY
                    )?.percentage || 0
                  }
                  peak={
                    externalConsumptionDataPercentages.find(
                      (ecdp) => ecdp.demandPeriod === EDemandPeriod.PEAK
                    )?.percentage || 0
                  }
                  offPeak={
                    externalConsumptionDataPercentages.find(
                      (ecdp) => ecdp.demandPeriod === EDemandPeriod.OFF_PEAK
                    )?.percentage || 0
                  }
                  onArrowClick={() => {}}
                />
              </Col>
            </Row>
          )}
          <div className="container-white mt-4">
            <Row className="align-items-center">
              <Col xs={{ order: 2 }} lg={{ order: 1 }} className="mt-4 mt-lg-0">
                <AppSelect
                  options={scheduleViewType}
                  selectedValue={selectedScheduleViewType}
                  onChangeOption={(selectedUsageOption) => {
                    setSelectedScheduleViewType(selectedUsageOption);
                  }}
                  fontSize="12"
                  fontWeight="400"
                />
              </Col>
              <Col xs={{ order: 1 }} lg={{ span: 8, order: 2 }} xl={7}>
                <Row className="align-items-center">
                  <Col className="col-12 col-sm">
                    <AppButton
                      text="More Info"
                      size="medium"
                      onClick={() => setShowUsageInfoModal(true)}
                      className="font-size-12 font-weight-500 px-2"
                      variant="transparentWithBorder"
                    />
                  </Col>
                  {profile?.serviceProvider === EProviders.LECO && (
                    <Col className="col-12 col-sm mt-4 mt-sm-0">
                      <AppTabs
                        buttons={[
                          {
                            buttonName: "Units",
                            selectedColor: "purpleColor",
                            id: 1,
                          },
                          {
                            buttonName: "Bill",
                            selectedColor: "purpleColor",
                            id: 2,
                          },
                        ]}
                        onChange={(id) => setSelectedButton(id)}
                        selectedTabId={selectedButton}
                      />
                    </Col>
                  )}
                  <Col className="col-12 col-sm mt-4 mt-sm-0">
                    <AppDatePicker
                      dateFormat="yyyy"
                      selectedDate={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      monthYearPicker={false}
                      yearPicker={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="position-relative mt-4">
              {selectedButton === 1 ? (
                selectedScheduleViewType.value === EScheduleViewType.ALL ? (
                  <AreaChart
                    borderColor1="#18A1AA"
                    borderColor2="#CB343D"
                    borderColor3="#6729CC"
                    borderColor4="#29CC39"
                    backgroundColor1="#DFFDFB4D"
                    backgroundColor2="#CC292920"
                    backgroundColor3="#8476DE20"
                    backgroundColor4="#29CC3917"
                    label1="Usage from Grid"
                    label2="Semi-Automated Schedule Usage"
                    label3="Manual Schedule Usage"
                    label4="Solar Generation"
                    yAxesUnit="Units"
                    labels={monthsList}
                    data1={chartData.grid}
                    data2={chartData.semiAuto}
                    data3={chartData.manual}
                    data4={chartData.solar}
                    hasForthDataSet={true}
                    isChartDataAvailable={chartDataAvailable}
                  />
                ) : selectedScheduleViewType.value ===
                  EScheduleViewType.MANUAL ? (
                  <AreaChart
                    borderColor1="#18A1AA"
                    borderColor2="#6729CC"
                    borderColor3="#29CC39"
                    backgroundColor1="#DFFDFB4D"
                    backgroundColor2="#8476DE20"
                    backgroundColor3="#29CC3917"
                    label1="Usage from Grid"
                    label2="Manual Schedule Usage"
                    label3="Solar Generation"
                    yAxesUnit="Units"
                    labels={monthsList}
                    data1={chartData.grid}
                    data2={chartData.manual}
                    data3={chartData.solar}
                    hasThirdDataSet={true}
                    isChartDataAvailable={chartDataAvailable}
                  />
                ) : selectedScheduleViewType.value ===
                  EScheduleViewType.SEMI_AUTOMATED ? (
                  <AreaChart
                    borderColor1="#18A1AA"
                    borderColor2="#CB343D"
                    borderColor3="#29CC39"
                    backgroundColor1="#DFFDFB4D"
                    backgroundColor2="#CC292920"
                    backgroundColor3="#29CC3917"
                    label1="Usage from Grid"
                    label2="Semi-Automated Schedule Usage"
                    label3="Solar Generation"
                    yAxesUnit="Units"
                    labels={monthsList}
                    data1={chartData.grid}
                    data2={chartData.semiAuto}
                    data3={chartData.solar}
                    hasThirdDataSet={true}
                    isChartDataAvailable={chartDataAvailable}
                  />
                ) : null
              ) : selectedScheduleViewType.value === EScheduleViewType.ALL ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#CB343D"
                  borderColor3="#6729CC"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#CC292920"
                  backgroundColor3="#8476DE20"
                  label1="Usage from Grid"
                  label2="Semi-Automated Schedule Usage"
                  label3="Manual Schedule Usage"
                  yAxesUnit="Rs."
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.semiAuto}
                  data3={chartData.manual}
                  hasThirdDataSet={true}
                  isChartDataAvailable={chartDataAvailable}
                />
              ) : selectedScheduleViewType.value ===
                EScheduleViewType.MANUAL ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#6729CC"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#8476DE20"
                  label1="Usage from Grid"
                  label2="Manual Schedule Usage"
                  yAxesUnit="Rs."
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.manual}
                  hasSecondDataSet={true}
                  isChartDataAvailable={chartDataAvailable}
                />
              ) : selectedScheduleViewType.value ===
                EScheduleViewType.SEMI_AUTOMATED ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#CB343D"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#CC292920"
                  label1="Usage from Grid"
                  label2="Semi-Automated Schedule Usage"
                  yAxesUnit="Rs."
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.semiAuto}
                  hasSecondDataSet={true}
                  isChartDataAvailable={chartDataAvailable}
                />
              ) : null}
              <SpinnerModal
                show={
                  isFetchingEnergyGeneration ||
                  isFetchingActualConsumptionByYear
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {!isFetchingSpace && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <UsageInfoModal
        show={showUsageInfoModal}
        onClose={() => setShowUsageInfoModal(false)}
        monthlyEnergySummeryView={monthlyEnergySummaryView}
      />
      <SpinnerModal show={isFetchingSpace} />
    </div>
  );
};

export default Home;
